// import 'firebase/performance' // If you need it
import { Logger } from '@firebase/logger'
import 'firebase/analytics'
// import { User } from 'firebase'
import firebase from 'firebase/app'
// import * as firebase from 'firebase/app'
import 'firebase/auth' // If you need it
import 'firebase/firestore' // If you need it
import 'firebase/storage'
import React, { useEffect, useState } from 'react'
import { AuthError } from 'src/utils/firebase/types/AuthError' // If you need it
import isBrowser from 'src/utils/libs/isBrowser'
// import User = firebase.User

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
}

// The type definition for the firebase context data.export
interface FirebaseContextData {
  user: firebase.User | null
  token: firebase.auth.IdTokenResult | null
  isSessionLoaded: boolean
  isInitialized: boolean
  firebase: typeof firebase
  authToken: string | null
  logout: () => void
  resetPassword: (email: string) => void
  signInWithEmailAndPassword: (email: string, password: string) => Promise<firebase.User | null>
}

// The firebase context that will store the firebase instance and other useful variables.
export const FirebaseContext = React.createContext<FirebaseContextData>({
  user: null,
  token: null,
  authToken: null,
  firebase,
  isSessionLoaded: false,
  isInitialized: false,
  resetPassword: () => {},
  logout: () => {},
  signInWithEmailAndPassword: () => {
    return new Promise(() => null)
  }
})

// The provider that will store the logic for manipulating the firebase instance and variables.
export const FirebaseProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [token, setToken] = useState<firebase.auth.IdTokenResult | null>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isSessionLoaded, setIsSessionLoaded] = useState<boolean>(false)

  // If we have a window and the authToken already exists in localstorage then initialize the authToken value otherwise null.
  const [authToken, setAuthToken] = useState<FirebaseContextData['authToken']>('')

  // If firebase has not been initialized then initialize it.
  if (isBrowser() && !firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
    setIsInitialized(true)

    // if (window.location.hostname === 'localhost') {
    //   console.log('localhost detected!')
    //   const firebaseApp = firebase.app() // that something we can export instead of export "firebase"
    //   const db = firebaseApp.firestore()
    //   db.settings({
    //     host: 'localhost:3600',
    //     ssl: false
    //   })
    // }

    // To enable analytics. https://firebase.google.com/docs/analytics/get-started
    if ('measurementId' in firebaseConfig) {
      firebase.analytics()
      // firebase.performance()
    }
  }

  /**
   * We validate the user token and check its permission
   */
  const startSession = async (user: firebase.User | null): Promise<firebase.User | null> => {
    let validUser = null
    if (user) {
      console.log(`Checking token...`)
      user?.getIdTokenResult().then((tokenResult) => {
        if (
          tokenResult?.claims?.merchantId &&
          (tokenResult.claims?.roles.includes('admin') ||
            tokenResult?.claims.roles.includes('merchant'))
        ) {
          setUser(user)
          setToken(tokenResult)
          setAuthToken(tokenResult.token)
          setIsSessionLoaded(true)
          validUser = user
        } else {
          // We should log out this weird guy...
          firebase
            .auth()
            .signOut()
            .catch(function (error) {
              console.error(error.message)
            })

          setUser(null)
          setToken(null)
          setAuthToken('')
          setIsSessionLoaded(true)

          firebase.analytics().logEvent('token_refresh', { email: user.email })
          firebase.analytics().logEvent('login', { email: user.email })
          firebase.analytics().logEvent('event_merchant_test', { some_param: 5 })

          const logClient = new Logger(`@firebase/auth`)
          logClient.info('SignIn success', user.email)
        }
      })
    } else {
      // We remove every tracks
      console.log(`Not a valid token...`)
      // cookies.remove('authToken')
      setUser(null)
      setToken(null)
      setAuthToken('')
      setIsSessionLoaded(true)
    }
    return validUser
  }
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      await startSession(user)
    })
    return () => unsubscribe()
  }, [authToken])

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      console.log(`refreshing token...`)
      const user = firebase.auth().currentUser
      if (user) await user.getIdToken(true)
    }, 10 * 60 * 1000)
    return () => clearInterval(handle)
  }, [])

  return (
    <FirebaseContext.Provider
      value={{
        user,
        token,
        authToken,
        firebase,
        isInitialized,
        isSessionLoaded,
        resetPassword: async (email: string) => {
          await firebase.auth().sendPasswordResetEmail(email)
        },
        logout: () => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              setAuthToken('')
            })
            .catch((e) => Promise.reject(e))
        },
        signInWithEmailAndPassword: async (
          email: string,
          password: string
        ): Promise<firebase.User | null> => {
          const logClient = new Logger(`@firebase/auth`)
          try {
            // Set the user if there is a user.
            const { user } = await firebase.auth().signInWithEmailAndPassword(email, password)

            const validUser = await startSession(user)
            if (!validUser) {
              const message = `Merchant permission denied for user ${email}`
              console.log(message)
              logClient.error(message)
            }
            return validUser
          } catch (e) {
            let authError: AuthError | undefined = e

            const message = `Merchant sign-in error ${authError?.code} for user ${email}`
            logClient.error(message, e)
            console.log(message)
            throw authError
          }
        }
      }}>
      {children}
    </FirebaseContext.Provider>
  )
}
