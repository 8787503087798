import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from 'src/locales/en/common.json'
import translationFr from 'src/locales/fr/common.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEn
      },
      fr: {
        translation: translationFr
      }
    },
    lng: 'fr',
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false
    }
  })
