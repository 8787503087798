import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Container } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'

type Props = {
  message: string
}

/**
 * @constructor
 */
export default function Maintenance({ message }: Props) {
  const classes = useStyles()

  return (
    <Container>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Page de Maintenance
          </Typography>
          <Typography variant="body2" component="p">
            <ReactMarkdown>{message}</ReactMarkdown>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})
