import * as React from 'react'
import { useContext } from 'react'
import { FirebaseContext } from 'src/utils/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import Maintenance from 'src/components/Views/Maintenance'
import isBrowser from 'src/utils/libs/isBrowser'
import { Label } from '@yodo/types'

export default function Page({ children }: any) {
  const { firebase } = useContext(FirebaseContext)

  const [label] = useDocumentData<Label>(
    isBrowser() ? firebase?.firestore().doc('labels/systemStatusMerchant') : null
  )

  let message: string = ''
  if (label?.additionalValues && typeof label.additionalValues[0] !== 'undefined') {
    message = label.additionalValues[0]?.value
  }

  return label && label.value === 'maintenance' ? (
    <Maintenance message={message} />
  ) : (
    <>{children}</>
  )
}
